import { useRouter } from 'next/router'
import React from 'react'
import { getAuth } from 'services/identity.service'

function LandingPage() {
  const router = useRouter()
  const faqs = [
    {
      question: 'How to place a meal plan order in premium breakfast + lunch',
      videoId: 'CjmWh7i3ECc',
    },
    {
      question: 'How to add on Snacks in Premium Breakfast + Lunch Plan?',
      videoId: 'hs3C071A_Yk',
    },
    {
      question: 'How to order the plan meal Lunch only 5 days?',
      videoId: 'EO90ptTDSfE',
    },
    {
      question: 'How to view plans in MonkeyBox app?',
      videoId: 'K2UEexXXCeM',
    },
    {
      question: 'How to cancel meal plan for the day?',
      videoId: '0w320R7r2pM',
    },
    {
      question: 'How to edit child details in the MonkeyBox app?',
      videoId: '8ZG4dIWYML0',
    },
    {
      question: 'How to cancel the subscription in Monkeybox app?',
      videoId: '6U_dzhNnbI8',
    },
    {
      question: 'How to cancel the order in Monkeybox app?',
      videoId: 't3AejRgU_kg',
    },
    {
      question: 'How to add lunch breakfast snacks?',
      videoId: 'L2nUeHmwpcM',
    },
    {
      question: 'What are the rates & delivery schedules for MonkeyBox meals?',
      answer:
        'Here are the rates & delivery plans for MonkeyBox meals: Breakfast: ₹75 onwards | Served 30 minutes before the morning break (between 8:30AM - 10:00AM, depending on the school) Lunch: ₹125 onwards (with a snack option for ₹90)| Served 30 minutes before the lunch break (between 11:30AM - 12:30PM, depending on the school)',
    },
    {
      question: 'How will my child get the MonkeyBox tiffin?',
      answer:
        'We drop the meals at the school reception before breaks, with a tag for the child to collect it easily. Certain schools even allow us to drop meals outside classrooms!',
    },
    {
      question: 'By when can the order be placed on MonkeyBox?',
      answer:
        'Please place an order, the evening before, by 8 pm! (We have initially left it opened till midnight, but this will end soon!)',
    },
    {
      question: 'How can I find the food menu of MonkeyBox for my kids?',
      answer:
        'We share a weekly menu every Saturday evening with a list of all the nutritious & tasty vegetarian food options!',
    },
    {
      question: 'How can I register with MonkeyBox to avail its services?',
      link: 'link',
    },
    {
      question: 'Is there a customer service number for MonkeyBox?',
      answer: 'If you have any queries, please contact us on +919513370337',
    },
    {
      question: 'How are the meals packaged for delivery?',
      answer:
        'The meals are packaged in plastic tiffin boxes. Your child’s name, class, section and combo is put in a sleeve on the jute bag and delivered half an hour before the break to the school. The school then gives the lunch box to your child. After that, the lunch box is collected by Monkeybox at the end of each day.',
    },
    {
      question: 'What plans are available?',
      answer:
        'PREMIUM WEEKLY PLAN – ₹569. 5 day lunch only (1 day cancellation available). Save over 10% opting for the premium lunch plan. 5 DAY BREAKFAST + LUNCH PLAN – ₹899. Options available (1 day cancellation available)',
    },
    {
      question: 'What varieties are present?',
      answer:
        'We give three options daily for Breakfast and Lunch and a snack add on option daily (Cuisines include North Indian, South Indian, Pan Asian and Continental cuisine)',
    },
    {
      question: 'How many schools does MonkeyBox deliver to?',
      answer:
        'We deliver to 80+ schools and top educational institutes across the South East & Eastern parts of Bangalore.',
    },
  ]

  const videoIds = ['8BmrSZNKN0M', 'UBjON2GIN60']
  const authCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('AUTH='))

  function scrollToDiv(sectionId) {
    var targetDiv = document.getElementById(sectionId)
    var offsetTop = targetDiv.offsetTop

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }

  const handleLogin = (e) => {
    const auth = getAuth()
    if (e) e.preventDefault()
    if (authCookie && auth && auth.roles.includes("Parent B2B2C")) {
      router.push(`/school-b2b2c/${auth.school.id}/menus`)
    }else if (authCookie) {
      router.push('/children')
    } else {
      router.push('/login')
    }
  }

  return (
    <div className="mx-auto">
      <div className="w-full bg-[#F5F5DC]">
        <div className="max-w-[1140px] mx-auto">
          <div className="p-[10px] w-full flex">
            <img
              src="images/MonkeyBox-logo-150x150.webp"
              className="md:h-[77px] md:w-[77px] h-10 w-10"
              alt="mblogo"
            />
            <div className="md:w-[86%] w-full md:justify-evenly flex flex-col md:flex-row md:items-center md:pl-[10px]">
              <ul className="flex flex-wrap justify-center md:justify-start items-center font-semibold text-black md:text-base text-[8px]">
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('process')}
                >
                  PROCESS
                </li>
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('aboutus')}
                >
                  ABOUT US
                </li>
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('vision')}
                >
                  OUR VISION
                </li>
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('testimonials')}
                >
                  TESTIMONIALS
                </li>
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('contact')}
                >
                  CONTACT
                </li>
                <li
                  className="px-[13px] py-[10px] after:bg-black relative after:absolute after:right-0 after:-top-[50%] after:h-[50%] after:w-[2px] after:content-center hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={() => scrollToDiv('faqs')}
                >
                  FAQ
                </li>
                <li
                  className="px-[13px] py-[10px] hover:border-b-[3px] hover:border-b-black transition-all ease-in-out duration-200 cursor-pointer"
                  onClick={(e) => handleLogin(e)}
                >
                  {authCookie ? 'PROFILE' : 'REGISTER / LOGIN'}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img
          src="images/banner-landing-page-v2.jpeg"
          alt=""
          className="w-full"
        />
      </div>

      <div className="bg-[#F8D5ED] md:p-[75px] p-[30px]" id="process">
        <div className="flex flex-col justify-center items-center">
          <img src="images/Group-121.svg" className="max-w-[116px] h-[60px]" />
          <h1 className="my-12 text-4xl leading-6 font-medium text-[##1E1E1E]">
            How It Works
          </h1>
          <div className="md:max-h-[500px] max-h-[300px] w-full">
            <div className="w-full flex flex-col justify-center items-center">
              <iframe
                className="md:h-[500px] h-[300px] md:w-[80%] w-full"
                src="https://www.youtube.com/embed/Ux7KgNKGsYM?si=NFUNtWmAFlnqGz4S"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              {/* <iframe  className='md:h-[500px] h-[300px] md:w-[80%] w-full mt-16' src="https://www.youtube.com/embed/ERLDxl_w1MA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#F2E6F0]" id="aboutus">
        <div className="max-w-[1140px] flex flex-col md:flex-row mx-auto">
          <div className="md:w-[50%] p-[10px]">
            <img
              src="images/slider-mobile-1.png"
              className="h-auto max-w-full"
              alt=""
            />
          </div>
          <div className="md:w-[50%] flex flex-col justify-center content-start p-[20px]">
            <h1 className="mb-5 text-black text-4xl leading-6 font-medium">
              What is MonkeyBox?
            </h1>
            <p className="mb-5 text-black text-lg break-words">
              MonkeyBox is a revolutionary tiffin service designed to reduce
              your stress of planning & preparing a balanced meal for your child
              every single day. We aim to keep your child healthy & happy with
              nutritious, variety-filled meals delivered straight to school
              every day of the week!
            </p>
            <button className="bg-[#00996D] rounded-[20px] text-white text-[15px] py-3 px-5 max-w-max">
              <a href="images/MB_Brochure-Front.pdf" target="_blank">
                Download Brochure
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#CDE6ED]" id="vision">
        <div className="max-w-[1140px] flex flex-col-reverse md:flex-row mx-auto">
          <div className="md:w-[50%] flex flex-col justify-center content-start p-[20px]">
            <h1 className="mb-5 text-black text-4xl leading-6 font-medium">
              The MonkeyBox Promise
            </h1>
            <p className="mb-5 text-black text-lg">
              At MonkeyBox, our vision is for every school-going kid to eat
              warm, healthy and nutritious meals twice a day. Our mission is to
              take away every parent’s worries about planning balanced meals for
              their children and deciding what to pack in their boxes every day!
            </p>
          </div>
          <div className="md:w-[50%] p-[10px] flex justify-center">
            <img
              src="images/slider-mobile-2.png"
              className="h-auto max-w-full"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-[#CDEACC]" id="contact">
        <div className="max-w-[1140px] flex flex-col mx-auto justify-center items-center">
          <div className="md:w-[50%] flex flex-col justify-center items-center px-[15px] my-15">
            <h1 className="mb-5 text-[#1E1E1E] text-4xl leading-6 font-medium">
              Get In Touch
            </h1>
            <h2 className="mb-[10px] text-lg text-[#1E1E1E] text-center">
              We’re always here to assist you!
            </h2>
            <h3 className="mb-5 text-base text-[#1E1E1E] text-center">
              Reach out to our customer care
              <br />@ +91 95133 70337
              <br />
              <a href="mailto:info@monkeybox.in" className="text-[#007bff]">
                info@monkeybox.in
              </a>
            </h3>

            <div className="p-[10px] mb-5 w-full">
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    id="field-name"
                    className="block w-full border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-0 mb-2 placeholder-gray-400"
                  />
                  <input
                    type="text"
                    placeholder="Phone no."
                    id="field-number"
                    className="block w-full border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-0 mb-2 placeholder-gray-400"
                  />
                  <input
                    type="text"
                    placeholder="Email Id"
                    id="field-email"
                    className="block w-full border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-0 placeholder-gray-400"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Locality"
                    id="field-locality"
                    className="block w-full border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-0 mb-2 placeholder-gray-400"
                  />
                  <select
                    type="text"
                    placeholder="City"
                    className="block w-full border-gray-300 text-gray-400 rounded-3xl shadow-sm focus:outline-none focus:ring-0 mb-2"
                  >
                    <option value="" disabled selected>
                      City
                    </option>
                    <option value="bangalore">Bangalore</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Pincode"
                    id="field-code"
                    className="block w-full border-gray-300 rounded-3xl shadow-sm focus:outline-none focus:ring-0 placeholder-gray-400"
                  />
                </div>
              </div>
            </div>
            <button className="bg-[#FF6D73] max-w-max rounded-3xl text-white text-sm font-normal py-2 px-8">
              Submit
            </button>
          </div>
        </div>
      </div>
      <div
        className="w-full bg-[#FDE6D4] py-[50px] md:px-16 px-9"
        id="testimonials"
      >
        <div className="flex flex-col justify-center items-center">
          <h1 className="mb-10 text-black md:text-4xl text-3xl leading-6 font-medium">
            Hear it from MonkeyBox Parents
          </h1>
          <div className="flex md:flex-row flex-col gap-8 md:gap-16 justify-center items-center w-full">
            {videoIds.map((videoId, index) => (
              <div key={index} className="w-full">
                <iframe
                  title={`video-${videoId}`}
                  width="100%"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full bg-[#CDE6ED] py-[50px]" id="faqs">
        <h2 className="text-3xl font-bold mb-6 text-center">FAQ's</h2>

        <div className="max-w-[1140px] mx-auto">
          <ul className="">
            {faqs.map((faq, index) => (
              <li key={index} className="border-b border-b-gray-300 p-3">
                <details className="group">
                  <summary className="flex items-center cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 mr-4 group-open:rotate-180 transition-transform duration-300"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <h3 className="text-base font-bold text-black">
                      {faq.question}
                    </h3>
                  </summary>
                  {faq.videoId ? (
                    <iframe
                      className="md:h-[500px] w-[80%]  h-[300px] ml-10 mt-4"
                      src={`https://www.youtube.com/embed/${faq.videoId}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  ) : faq.link ? (
                    <p className="mt-4 text-gray-700 ml-10">
                      — You can download our app on Google Play Store:{' '}
                      <a href="https://play.google.com/store/apps/details?id=com.monkeybox.monkeyboxapp">
                        https://play.google.com/store/apps/details?id=com.monkeybox.monkeyboxapp
                      </a>{' '}
                      <p>
                        — You can download our app on IOS:{' '}
                        <a href="https://apps.apple.com/in/app/monkeybox-for-kids/id1626287253">
                          https://apps.apple.com/in/app/monkeybox-for-kids/id1626287253
                        </a>
                      </p>
                      <p>
                        — Alternatively, you can register on{' '}
                        <a href="https://monkeybox.in/">https://monkeybox.in</a>
                      </p>
                    </p>
                  ) : (
                    <p className="mt-4 text-gray-700 ml-10">— {faq.answer}</p>
                  )}
                </details>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full bg-[#FDE6D4] py-[50px]">
        <div className="max-w-[1140px] mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-32">
            <div className="flex flex-col text-left">
              <h1 className="text-2xl md:text-3xl mb-2 font-semibold">
                NOVO LABS TECHNOLOGY PRIVATE LIMITED
              </h1>
              <p className="font-semibold text-base mb-2">
                No. 42/3/C2, Haralukunte village,
              </p>
              <p className="font-semibold text-base mb-2">
                Bommanahalli, B.B.M.P South,
              </p>
              <p className="font-semibold text-base">Karnataka-560068</p>
            </div>
            <div className="flex flex-col text-left">
              <h1 className="text-2xl md:text-3xl mb-2 font-semibold">
                OUR TERMS
              </h1>
              <a
                href="https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+-+Terms+of+Use+-Web.pdf"
                target="_blank"
                className="font-semibold text-base mb-3 text-[#007bff] block"
              >
                Terms of use
              </a>
              <a
                href="https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+Privacy+Policy.pdf"
                target="_blank"
                className="font-semibold text-base mb-3 text-[#007bff] block"
              >
                Privacy policy
              </a>
              <a
                href="https://mbbrocure.s3.ap-south-1.amazonaws.com/MonkeyBox+-+Terms+of+Use+-Web.pdf"
                target="_blank"
                className="font-semibold text-base text-[#007bff] block"
              >
                Refund & Cancellation Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-300 py-[20px] flex justify-center">
        <div className="max-w-[1140px]">
          <div className="flex flex-col items-center md:flex-row md:justify-between md:gap-20 gap-2">
            <h1 className="text-gray-700 text-center md:text-left md:text-3xl text-base font-bold">
              For better experience,download <br />
              the MonkeyBox app now
            </h1>
            <div className="flex flex-row md:gap-4 gap-2">
              <a href="https://apps.apple.com/in/app/monkeybox-for-kids/id1626287253">
                <img
                  src="images/app_store.png"
                  className="md:max-w-[208px] max-w-[151px] md:h-16 h-12"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.monkeybox.monkeyboxapp">
                <img
                  src="images/play_store.png"
                  className="md:max-w-[208px] max-w-[151px] md:h-16 h-12"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-[#EF4662] py-[20px] flex justify-center">
        <div className="max-w-[1140px]">
          <div className="flex flex-col items-center md:flex-row md:justify-between">
            <h1 className="text-white text-center md:text-left md:text-lg text-sm font-medium">
              Copyright MonkeyBox 2022 – Registered Company: Novo Labs
              Technology Private Limited
            </h1>
          </div>
        </div>
      </div>

      <div className="w-full bg-white py-[20px] flex justify-center">
        <div className="max-w-[1140px]">
          <div className="flex flex-col justify-center items-center">
            <img
              src="images/2021-website-newimages.png"
              width={300}
              height={276}
              className="mb-14"
            />
            <h1 className="text-black md:text-3xl text-lg font-semibold mb-3">
              Register Now for a Seamless Experience
            </h1>
            <em className="text-black md:text-base text-sm font-semibold mb-3">
              “Ordering meals has never been more convenient”
            </em>
            <button
              className={
                authCookie
                  ? 'hidden'
                  : 'bg-[#EF4662] text-white md:text-2xl text-xl font-normal w-[70%] py-3 rounded-[41px]'
              }
              onClick={(e) => handleLogin(e)}
            >
              Register / Login
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
